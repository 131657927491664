import logo from "./logo.svg";
import "./App.css";
import { useTranslation, Trans } from "react-i18next";
import Carousel from "./components/carousel";

const lngs: { [key: string]: { nativeName: string } } = {
  en: { nativeName: "English" },
  de: { nativeName: "Deutsch" },
};

function App() {
  const { t, i18n } = useTranslation();

  console.log(i18n.language);

  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
          {Object.keys(lngs).map((lng) => (
            <button
              key={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              }}
              type="submit"
              onClick={() => i18n.changeLanguage(lng)}
            >
              {lngs[lng].nativeName}
            </button>
          ))}
        </div>
        <p>
          <Trans i18nKey="description.part1">
            Edit <code>src/App.js</code> and save to reload.
          </Trans>
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("description.part2")}
        </a>
      </header> */}

      <section id="header">
        <div className="header container">
          <div className="nav-bar">
            <div className="brand">
              <a href="#services">
                <h1>
                  <span>F</span>aial <span>T</span>ravels
                </h1>
              </a>
            </div>
            <div className="nav-list">
              <div className="language">
                <img id="selected-lang" alt="lang" src="img/eng.png" />
                <div className="lang-panel">
                  <div className="lang-block">
                    <span className="arrow"></span>
                    <ul id="lang-btns">
                      <li id="eng">
                        <img alt="eng" src="img/eng.png" />
                        <p>English</p>
                      </li>
                      <li id="rus">
                        <img alt="rus" src="img/rus.png" />
                        <p>Русский</p>
                      </li>
                      <li id="pt">
                        <img alt="pt" src="img/pt.png" />
                        <p>Portugal</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="hamburger">
                <div className="bar"></div>
              </div>
              <ul id="action-btns">
                <li>
                  <a href="#start" data-after="Home">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#about" data-after="Projects">
                    About
                  </a>
                </li>
                <li>
                  <a href="#services" data-after="Service">
                    Services
                  </a>
                </li>
                <li>
                  <a href="#contact" data-after="Contact">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="start">
        <div className="start container">
          <div>
            <div className="welcome">
              <h1>
                Hello! <span></span>
              </h1>
              <h1>
                And welcome to Faial.<span></span>
              </h1>
              <h1>
                <a href="#about" type="button" className="start-btn">
                  let's start the journey
                </a>
                <span></span>
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section id="about">
        <div className="about">
          <div className="about-header">
            <h1 className="section-title">
              <span>F</span>aial <span>I</span>sland
            </h1>
          </div>
          <div className="service-top">
            <p>
              Every day, there is an adventure waiting to happen in Faial. With
              it's nature to explore and enjoy its beauty. Faial is truly an
              outdoor enthusiast's paradise. But, it is also a warm and
              welcoming place for the rest of us.
            </p>
          </div>
          <Carousel />
        </div>
      </section>

      <section id="services">
        <div className="services container">
          <div className="service-top">
            <h1 className="section-title">
              <span>S</span>ervices
            </h1>
          </div>
          <div className="service-bottom">
            <div className="service-item">
              <div className="icon">
                <img alt="Transfer" src="img/transfer.png" />
              </div>
              <h2>Transfer</h2>
              <p>
                Fable uses a dark shade of green in combination with white and
                pink to create an appealing look and sense of comfort. The brand
                promotes a modern way to discover, read, and talk about great
                books together with friends.
              </p>
              <a href="#contact" type="button" className="ctb">
                Contact us
              </a>
            </div>
            <div className="service-item">
              <div className="icon">
                <img alt="Rent" src="img/rent.png" />
              </div>
              <h2>Apartment rent</h2>
              <p>
                Fable uses a dark shade of green in combination with white and
                pink to create an appealing look and sense of comfort. .
              </p>
              <a href="#contact" type="button" className="ctb">
                Contact us
              </a>
            </div>
            <div className="service-item">
              <div className="icon">
                <img alt="Tours" src="img/10193665.png" />
              </div>
              <h2>Tours</h2>
              <p>
                Fable uses a dark shade of green in combination with white and
                pink to create an appealing look and sense of comfort. The brand
                promotes a modern way to discover, read, and talk about great
                books together with friends.
              </p>
              <a href="#contact" type="button" className="ctb">
                Contact us
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="contact container">
          <div>
            <h1 className="section-title">
              <span>C</span>ontact <span>i</span>nfo
            </h1>
          </div>
          <div className="contact-items">
            <div className="contact-item">
              <div className="icon">
                <img alt="phone" src="img/phone.png" />
              </div>
              <div className="contact-info">
                <h1>Phone</h1>
                <h2>
                  <a target="_blank" href="tel:351 935 519 296">
                    +351 935 519 296
                  </a>
                </h2>
                {/* <h2>
                  <a target="_blank" href="tel:1 234 123 1234">
                    +1 234 123 1234
                  </a>
                </h2> */}
              </div>
            </div>
            <div className="contact-item">
              <div className="icon">
                <img alt="email" src="img/email.png" />
              </div>
              <div className="contact-info">
                <h1>Email</h1>
                <h2>
                  <a target="_blank" href="mailto:vnzmdhorta@gmail.com">
                    vnzmdhorta@gmail.com
                  </a>
                </h2>
                {/* <h2>
                  <a target="_blank" href="mailto:info@gmail.com">
                    abcd@gmail.com
                  </a>
                </h2> */}
              </div>
            </div>
            <div className="contact-item">
              <div className="icon">
                <img alt="address" src="img/address.png" />
              </div>
              <div className="contact-info">
                <h1>Address</h1>
                <h2>
                  <a
                    target="_blank"
                    href="http://maps.google.co.uk/maps?q=38.58656801678438,-28.705194107115464"
                  >
                    Faial island
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="footer">
        <div className="footer container">
          <div className="brand">
            <h1>
              <span>F</span>aial <span>T</span>ravels
            </h1>
          </div>
          <h2>Tours and transfers around the island of Faial</h2>
          <div className="social-icon">
            <div className="social-item">
              <a target="_blank" href="https://www.youtube.com/@user-azores">
                <img alt="youtube" src="img/youtube.png" />
              </a>
            </div>
          </div>
          <p>Copyright © 2023. All rights reserved</p>
        </div>
      </section>
    </div>
  );
}

export default App;
