// import Flickity from "react-flickity-component";

export default function Carousel() {
  return (
    <div className="carousel">
      {/* <Flickity
        className="gallery" // default ''
        elementType={"div"} // default 'div'
        options={{ initialIndex: 0, wrapAround: true }} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        <img src={require("./carousel_images/1.jpg")} alt="Carousel 1" />
        <img src={require("./carousel_images/2.jpg")} alt="Carousel 2" />
        <img src={require("./carousel_images/3.jpg")} alt="Carousel 3" />
        <img src={require("./carousel_images/4.jpeg")} alt="Carousel 4" />
      </Flickity> */}
      <img src={require("./carousel_images/4.jpeg")} alt="Carousel 4" />
      <a href="#services" type="button" className="ctb">
        Explore
      </a>
    </div>
  );
}
