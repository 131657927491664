import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';

document.addEventListener('scroll', () => {
  var scroll_position = window.scrollY;

  console.log(scroll_position);

  const el = document.querySelector('.header.container');
  if (el === null) {
    return;
  }

  console.log(el);

  if (scroll_position > 250) {
    el.setAttribute("style", "background-color: #29323c");
  } else {
    el.setAttribute("style", "background-color: transparent");
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
